<template>
    <v-dialog v-model="dialog" max-width="290">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small v-bind="attrs" v-on="on" v-text="'delete'"/>
        </template>
        <v-card>
            <v-card-title class="headline" v-text="'Confirmation'"/>

            <v-card-text>
                Are you sure you want to delete "{{model.name}}"? This cannot be undone
            </v-card-text>

            <v-card-actions>
                <v-btn color="red darken-1" text @click="dialog = false" v-text="'Cancel'"/>
                <v-btn color="green darken-1" text @click="handleDelete" v-text="'Confirm'"/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: 'DeleteDialog',
    props: {
        model: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            dialog: false,
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async handleDelete() {
            await window.axios.delete(`/api/${this.type}/${this.model.id}`)
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
            this.dialog = false
            this.$emit('success', this.model)
        },
    }
}
</script>

<style scoped>

</style>
