<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn @click="$router.push({name: 'admin.faq.create'})" v-text="'Create'"/>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="faq"
            :items-per-page="10"
            class="elevation-1"
            @click:row="handleClickRow">
            <template v-slot:item.parent_id="{ item }">
                {{ faq.find(f => f.id === item.id).title || '-' }}
            </template>
            <template v-slot:item.is_active="{ item }">
                {{ item.is_active ? 'Yes' : 'No' }}
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="faq" @success="deleted"/>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import Faq from "../../../models/Faq";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
    components: {
        AdminDashboardLayout,
        DeleteDialog
    },
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Title', value: 'title' },
                { text: 'Parent', value: 'parent_id' },
                { text: 'Active', value: 'is_active' },
                { text: 'Actions', value: 'actions' },
            ],
            faq: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            this.loading = true
            this.faq = await Faq.get()
            this.loading = false
        },
        handleClickRow(faqItem) {
            this.$router.push({name: 'admin.faq.edit', params: {id: faqItem.id}})
        },
        deleted(model) {
            this.faq.splice(this.faq.findIndex(f => f.id === model.id), 1);
        }
    }
}
</script>
